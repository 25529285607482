import React,{useState,createRef,useRef,useEffect,forwardRef, useImperativeHandle} from 'react'
import * as firebase from 'firebase'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import {base_url} from '../'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios'
import Cookies from 'universal-cookie';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Pagination } from '@material-ui/lab';
import { PagesRounded } from '@material-ui/icons';

const cookies = new Cookies();

const COOKIE_AGE=315360000
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  root1:{
    height:'100%',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
    flexShrink: 0 // Prevents the image from shrinking
  },
  stepperRoot: {
    width: '100%',
  },
  canvasPaper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2,1),
  },
  root1:{
    height:'100%'
  },
  eliminationGrid : {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    }
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '250px', // Fixed height for content
    overflow: 'hidden'
  },
}));

const ProblemsList=forwardRef((props,ref)=>{

  useImperativeHandle(ref, () => ({
    update(){
      fetchProblems()
    },
    updateWithUid(uid){
      fetchProblemsWithUid(uid)
    },
    updateWithEmail(email){
      fetchProblemsWithEmail(email)
    }
  }));

  const classes = useStyles();

  const [problems,setProblems]=useState(null)
  const [totalPage,setTotalPage]=useState(1)
  const [page, setPage] = useState(1);
  const [problemsPerPage] = useState(10);
  const getUserData = () => {
   
      const cookieData = cookies.get('userData');
      return cookieData
    
  }
  const fetchProblems=async()=>{
    // firebase.firestore().collection('problem').where('uid','==',props.uid=='self'?firebase.auth().currentUser.uid:props.uid).orderBy('timestamp','desc').get().then(res=>{
    //   var arr=[]
    //   res.docs.map(doc=>{
    //     var data=doc.data()
    //     data['id']=doc.id
    //     arr.push(data)
    //   })
    //   setProblems(arr)
    // })
    const userData = getUserData();

  // console.log(userData); // Access your user object
  // userData.name, userData.email etc.

    setProblems(null)
    let email2="";
    console.log(props.user)
    if(props.uid==='self'){
      email2= userData.email
    }else{
      email2=props.email;
    }
    var res= await axios.get(base_url+'/api/v2/setter/problem?authorEmail='+email2+'&page='+page,
      {
        headers: {
          // Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJlbWFpbCI6InJhYmliamFoaW4yMDAwQGdtYWlsLmNvbSIsInJvbGUiOiJzZXR0ZXIiLCJpYXQiOjE3MzcwNDQ2MjR9.wUle3xeifmTHJkmM0WscLU2aqoz-7vWoCYBP1AjyLAQ`
      
      Authorization: `${cookies.get('token')}`
        }
      }).then(res=>{
        console.log(res.data)
        setProblems(res.data.problems)
        setTotalPage(res.data.totalPages)
      })
  }

  const fetchProblemsWithEmail=async(email)=>{
    setProblems(null)
    // const userData = getUserData();
  
      // console.log(userData); // Access your user object
      // userData.name, userData.email etc.
    
    try {
      const res = await axios.get(
        base_url+'/api/v2/setter/problem?authorEmail='+email+'&page='+page,
        {
          headers: {
            // Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJlbWFpbCI6InJhYmliamFoaW4yMDAwQGdtYWlsLmNvbSIsInJvbGUiOiJzZXR0ZXIiLCJpYXQiOjE3MzcwNDQ2MjR9.wUle3xeifmTHJkmM0WscLU2aqoz-7vWoCYBP1AjyLAQ`
        Authorization: `${cookies.get('token')}`
          }
        }
      );
      setProblems(res.data.problems)
      // setUsers(res.data.authors)
      console.log(res.data); // Handle the response here
      setTotalPage(res.data.totalPages)
    } catch (err) {
      console.error("Error fetching data:", err); // Handle the error here
    }
  }

  const fetchProblemsWithUid=uid=>{
    setProblems(null)
    firebase.firestore().collection('problem').where('uid','==',uid).orderBy('timestamp','desc').get().then(res=>{
      var arr=[]
      res.docs.map(doc=>{
        var data=doc.data()
        data['id']=doc.id
        arr.push(data)
      })
      setProblems(arr)
    })
  }

  useEffect(() => {
    fetchProblems()
  },[]);

  useEffect(() => {
    fetchProblems()
  },[page]);
  
  const handlePageChange = (event, value) => {
    setPage(value);
    // console.log(value)
    window.scrollTo(0, 0);
  };

  const indexOfLastProblem = page * problemsPerPage;
  const indexOfFirstProblem = indexOfLastProblem - problemsPerPage;
  const currentProblems = problems;

  return(
    <div>
      {
        problems==null?(
          <LinearProgress/>
        ):(
          <div>
            {
              props.uid!='self'?(
                <div>
                  <ArrowBackIcon onClick={()=>{props.setMenu(1)}} style={{cursor:'pointer'}}/><br/><br/>
                </div>
              ):(
                <div/>
              )
            }
            <Grid direction='row' alignItems="stretch" container spacing={1} className={classes.grid}>
              {
                currentProblems.map((problem,ind)=>{
                    return(
                    <Grid style={{height:'100%'}} item xs={6} md={4}>
                    <Card className={classes.root1} onClick={()=>{props.load(problem)}}>
    <CardActionArea className={classes.cardActionArea}>
        <img 
            src={problem.logo} 
            alt="Problem Logo" 
            className={classes.media} 
            style={{ 
                width: '100%', 
                height: '200px', 
                objectFit: 'cover' 
            }} 
        />
        <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h6" component="h6" noWrap>
                {problem.title}
            </Typography>
            <Typography gutterBottom variant="body2" component="body2">
                ID : {problem.id}
            </Typography>
            <Typography gutterBottom variant="body2" component="body2">
                {
                    problem.draft ==null || problem.draft? (
                        <font color='#999900'>Draft</font>
                    ) : (
                        <font color='#00aa00'>Submitted</font>
                    )
                }
            </Typography>
            <div style={{ marginTop: 'auto' }}>
                <Typography gutterBottom variant="body2" component="body2">
                    <font color='#888888'>
                        Created: {new Date(problem.created_at).toLocaleString()}
                    </font>
                </Typography><br/>
                <Typography gutterBottom variant="body2" component="body2">
                    <font color='#888888'>
                        Updated: {new Date(problem.updated_at).toLocaleString()}
                    </font>
                </Typography>
            </div>
        </CardContent>
    </CardActionArea>
</Card>
                    </Grid>
                    )
                })
              }
            </Grid>
            <div className={classes.paginationContainer}>
              <Pagination 
                count={totalPage}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size={window.innerWidth < 600 ? "small" : "medium"}
              />
            </div>
          </div>
        )
      }
    </div>
  )
})

export default ProblemsList
