import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const env = process.env.REACT_APP_ENV;
const base_url = env === 'dev' 
    ? process.env.REACT_APP_BASE_URL_LOCAL 
    : process.env.REACT_APP_BASE_URL_PROD;



export { base_url };

ReactDOM.render(
      <DndProvider backend={HTML5Backend}>
         <App />
      </DndProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
