import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle, createRef} from 'react'
import {
  Typography, 
  Button, 
  Divider, 
  TextField, 
  Select, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions
} from '@material-ui/core';
import MDEditor,{commands} from '@uiw/react-md-editor';
import * as firebase from 'firebase'
import AnsType from './AnsType'
import Cookies from 'universal-cookie';
import axios from 'axios'
import Questionnaire from './Questionnaire'
import Latex from "react-latex";
import Grid from "@material-ui/core/Grid";
import {base_url} from '../'
import {MathJax, MathJaxContext} from "better-react-mathjax";
const cookies = new Cookies();

const COOKIE_AGE=315360000
const languages=[
  'English',
  'Bengali'
]

const ANS_TYPE={

 
 'mcq':1,
 'text':2,
 'interactive':3

}
const QUESTION_TYPES = {
   'None':1,
   'exclusion grid':2,
   'drag and drop':3,
   'grouping containers':4,
   'rearranging':5,
   "matchstick":6,
  'venn':7,
  'drag and drop grid':8
};
const Problem=props=>{

  const titleRef=useRef()
  const logoRef=useRef()
  const gradeRef=useRef()
  const catRef=useRef()
  const restrictionsRef=useRef()
  const tagsRef=useRef()

    const [latex,setLatex]=useState('latex' in props.data?props.data.latex:'')
    const [openDialog, setOpenDialog] = useState(false);

    const handleLatexChange=e=>{
      setLatex(e.target.value)
    }
    const mapInteractiveTypeReverse=(intType)=>{
      switch (intType) {
      case 1: return 'none';
      case 2: return 'exclusion_grid';
      case 3: return 'dragAndDrop-2';
      case 4: return 'dragAndDrop-1';
      case 5: return 'rearrange';
      case 6: return 'matchstick';
      case 7: return 'venn';
      case 8: return 'dragAndDrop-3-Grid';
      default: return 'none';
      }
    }
    const mapInteractiveType=(strType)=>{

      switch (strType) {
        case 'none': return 1;
        case 'exclusion_grid': return 2;
        case 'dragAndDrop-2': return 3;
        case 'dragAndDrop-1': return 4;
        case 'rearrange': return 5;
        case 'matchstick': return 6;
        case 'venn': return 7;
        case 'dragAndDrop-3-Grid' : return 8;
        default: return 2;
      }

    }
const mapDifficulty=(strDifficulty)=>{

  switch (strDifficulty) {
    case 'easy': return 1;
    case 'medium': return 2;
    case 'hard': return 3;
    default: return 0;
  }

}


const mapDifficultyReverse=(intDifficulty)=>{
  switch (intDifficulty) {
    case 1: return 'easy';
    case 2: return 'medium'; 
    case 3: return 'hard';
    default: return 'none';
  }
}


  const [loaded,setLoaded]=useState(false)

  const solRef=useRef()

  const questionnaireRef=useRef()

    const hintRef=useRef()

  const [ansType,setAnsType]=useState('answer_type' in props.data?props.data['answer_type']=='interactive'?3:props.data['answer_type']=='mcq'?1:2:2)
  const [language,setLanguage]=useState('language' in props.data?props.data.language=='en'?1:2:0)
  const [interactiveType,setInteractiveType]=useState('interactive_type' in props.data ? mapInteractiveType(props.data['interactive_type']) : 0)
  const [difficulty,setDifficulty]=useState('difficulty' in props.data?mapDifficulty(props.data.difficulty):0)
  const [isForTest,setIsForTest]=useState(0)

  useEffect(()=>{
     window.scrollTo(0,0)
     setLoaded(true)
     console.log(interactiveType)
  },[])

  const validateString=string=>{
    return !(string.trim().length==0)
  }

  const validateNumber=num=>{
    return num!=NaN && num>0
  }

  const validate=()=>{
    var title=titleRef.current.value
    var logo=logoRef.current.value
    var grade=parseInt(gradeRef.current.value)
    var cat=catRef.current.value
    var restrictions=restrictionsRef.current.value
    var tags=tagsRef.current.value

    if(!validateString(title)){
      window.alert('error : title')
      return false
    }
    if(!validateString(logo)){
      window.alert('error : logo')
      return false
    }
    if(!validateNumber(grade)){
      window.alert('error : grade')
      return false
    }
    // if(!validateNumber(difficulty)){
    //   window.alert('error : difficulty')
    //   return false
    // }
    if(!validateNumber(language)){
      window.alert('error : language')
      return false
    }
    if(!validateString(cat)){
      window.alert('error : category')
      return false
    }
    if(!validateString(mdStatement)){
      window.alert('error : problem statement')
      return false
    }
    if(!validateNumber(interactiveType)){
      window.alert('error : interactive type')
      return false
    }
    if(!validateString(mdExplanation)){
      window.alert('error : explanation')
      return false
    }
    if(!validateNumber(ansType)){
      window.alert('error : answer type')
      return false
    }
    if(!solRef.current.isValid()){
      window.alert('error : solution')
      return false
    }
    return true
  }

  const getData=()=>{
    var title=titleRef.current.value
    var logo=logoRef.current.value
    var grade=parseInt(gradeRef.current.value)
    var cat=catRef.current.value
    var restrictions=restrictionsRef.current.value
    var tags=tagsRef.current.value

    var data={}

    if(validateString(title))data['title']=title
    if(validateString(logo))data['logo']=logo
    if(validateNumber(grade))data['grade']=grade
    if(validateNumber(difficulty))data['difficulty']=parseInt(difficulty)
    if(validateNumber(language))data['language']=parseInt(language)
    if(validateString(cat))data['cat']=cat
    if(validateString(mdDescription))data['description']=mdDescription
    if(validateString(mdStatement))data['statement']=mdStatement
    if(validateString(restrictions))data['restrictions']=parseDetails(restrictions)
    if(validateString(tags))data['tags']=parseDetails(tags)

      data['latex']=latex

      if(hintRef.current.getData().length>0)data['hint']=hintRef.current.getData()
       
    if(validateNumber(interactiveType)){
      data['interactiveType']=parseInt(interactiveType)
      if(validateString(mdExplanation))data['explanation']=mdExplanation
      if(validateNumber(ansType))data['ansType']=ansType
      //console.log(questionnaireRef.current.getData())
      if(interactiveType>1){
        if(questionnaireRef.current.getData()!=null)
          data['questionnaire']=JSON.stringify(questionnaireRef.current.getData())
      }
      if(solRef.current!=undefined){
        var solData=solRef.current.getData()
        if(ansType<3){
          Object.keys(solData).map(key=>{
            data[key]=solData[key]
          })
        }else{
          data['answer']=JSON.stringify(solData)
        }

      }
    }
// console.log(data)
    return data
  }

  const request_data=(draft,data)=>{

    data['draft']=draft
    
    // data['created_at']=Date.now()
    data['answer_type']=data['ansType']==1?'mcq':data['ansType'] ==2?'text':data['ansType']==3?'interactive':'none'
    data['interactive_type']=mapInteractiveTypeReverse(data['interactiveType'])
    data['category']=data['cat']
    data['difficulty']=data['difficulty']!=undefined?mapDifficultyReverse(data['difficulty']):'none'
    data['restrictions'] = JSON.stringify(data['restrictions'])
    data['language']=data['language']==1?'en': data['language']==2?'bn':'none'
    data['is_pending']=true
    data['is_for_test']=isForTest>0?true:false
    data['answer']=data['answer']?JSON.stringify(data['answer']):''
    data['hint'] = data['hint']?JSON.stringify(data['hint']):''

    axios.put(
      base_url+'/api/v2/setter/problem/'+props.data.id,
      data ,
      {
        headers: {
          Authorization: `${cookies.get('token')}`
        }
      }
    )
      .then(res => {

if(draft)       
        props.notify('Draft saved')
  else{

    props.notify('Problem Successfully Submitted')
  }
        console.log(res.data)
      })
      .catch(err => {
        console.error("Error uploading data:", err);
      });

  }
  const saveDraft=()=>{
    var data=getData()
  
    request_data(true,data)
    


  }

  const submit=()=>{
    // console.log(props.data)
    var data=getData()
    if(validate()){
      
      request_data(false,data)
      
//       // console.log(diffi)
//       var data=getData()
//       // data['uid']=props.data.uid
//       data['draft']=false
//       data['updated_at']=Date.now()
//         data['isPending']=true
      
//         data['answer_type']=data['ansType']==1?'mcq':data['ansType'] ==2?'text':data['ansType']==3?'interactive':'none'
// data['interactive_type']=mapInteractiveTypeReverse(data['interactiveType'])
// data['category']=data['cat']
// data['difficulty']=data['difficulty']!=undefined?mapDifficultyReverse(data['difficulty']):'none'
// data['restrictions'] = JSON.stringify(data['restrictions'])
// data['language']=data['language']==1?'en': data['language']==2?'bn':'none'
// data['is_pending']=data['isPending']
// data['is_for_test']=isForTest>0?true:false
// data['hint'] = data['hint']?JSON.stringify(data['hint']):''
// // data['ipdated']
// firebase.firestore().collection('problem').doc(props.data.id).update(data).then(res=>{
      //   props.notify("Problem Uploaded Successfully")
      //   //props.update()
      //   props.close()
      // })
      console.log(data)
    }
    // console.log(i)
  }

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteProblem = () => {
    handleCloseDialog();
    axios.delete(
      base_url+'/api/v2/setter/problem/'+props.data.id,
      {
        headers: {
          Authorization: `${cookies.get('token')}`
        }
      }
    )
      .then(res => {
        props.notify('Problem Successfully deleted')
        props.close()
      })
      .catch(err => {
        props.notify("Error in deleting the problem");
      });
  };

  const handleAnsType=event=>{
    var val=event.target.value
    if(val==3 && questionnaireRef.current.getData()==null)
      window.alert("Set the questionnaire properly")
    else
      setAnsType(event.target.value)
  }

  const handleInteractiveType=event=>{
    setInteractiveType(event.target.value)
    setAnsType(0)
  }
  const handleTest=event=>{
    setIsForTest(event.target.value)
  }
  const handleDifficulty=event=>{
    setDifficulty(event.target.value)
    
  }

  const handleLanguage=event=>{
    setLanguage(event.target.value)
  }
  const parse = (restrictionString) => {
    try {
      if (!restrictionString) return [];
      return JSON.parse(restrictionString);
    } catch (e) {
      console.error("Error parsing restrictions:", e);
      return [];
    }
  }
  

  const close=()=>{
    props.close()
  }

  const detailsString=data=>{
    var string=''
    data&&data.map(entry=>{
      string+=entry+'|'
    })
    return string.substr(0,string.length-1)
  }

  const parseDetails=details=>{
    var output=[]
    var arr=details.split('|')
    arr.map(item=>{
      if(item.trim().length>0)
        output.push(item.trim())
    })
    return output
  }

  const [mdDescription,setMdDescription]=useState('description' in props.data?props.data.description!=null?props.data.description:'':'')
  const [mdStatement,setMdStatement]=useState('statement' in props.data?props.data.statement!=null?props.data.statement:'':'')
  const [mdExplanation,setMdExplanation]=useState('explanation' in props.data?props.data.explanation!=null?props.data.explanation:'':'')

    const [hintN,setHintN]=useState('hint' in props.data && Array.isArray(parse(props.data.hint))?parse(props.data.hint).length:0)


    const handleHintN=event=>{
        var val=parseInt(event.target.value)
        if(validateNumber(val))
            setHintN(val)
        else
            setHintN(0)
    }


    useEffect(() => {
      if (!mdDescription || typeof mdDescription !== 'string') {
        return;
      }
    
      try {
        const newStr = mdDescription.replaceAll("**", "__");
        if (newStr !== mdDescription) {
          setMdDescription(newStr);
        }
      } catch (error) {
        console.error('Error processing markdown:', error);
      }
    }, [mdDescription]);

    useEffect(() => {
      if (!mdStatement || typeof mdStatement !== 'string') {
        setMdStatement(''); // Set empty string instead of null
        return;
      }
      
      try {
        const str = mdStatement.replace("**","__");
        setMdStatement(str);
      } catch (error) {
        console.error('Error processing markdown:', error);
        setMdStatement('');
      }
    }, [mdStatement]);

    useEffect(() => {
      if (!mdExplanation || typeof mdExplanation !== 'string') {
        setMdExplanation('');
        return;
      }
      
      try {
        const str = mdExplanation.replace("**","__");
        setMdExplanation(str);
      } catch (error) {
        console.error('Error processing markdown:', error);
        setMdExplanation('');
      }
    }, [mdExplanation]);

  return(
    <div>
      <Button
        style={{width:'24%'}}
        variant='outlined'
        color='primary'
        onClick={saveDraft}
        disabled={props.data.draft==null?false:!props.data.draft || props.data['author_email']!=cookies.get('userData').email}>
        Save Draft
      </Button>
      <Button
        style={{width:'24%',marginLeft:'1%'}}
        variant='outlined'
        onClick={submit}
        disabled={props.data['author_email']!=cookies.get('userData').email}
        color='primary'>
        Submit
      </Button>
      <Button
        style={{width:'24%',marginLeft:'1%'}}
        variant='outlined'
        color='secondary'
        onClick={handleDeleteClick}
        disabled={props.data['author_email']!=cookies.get('userData').email}>
        Delete
      </Button>
      <Button
        style={{width:'24%',marginLeft:'2%'}}
        variant='outlined'
        color='primary'
        onClick={close}>
        Close
      </Button>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this problem? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteProblem} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <center>
        <Typography style={{marginTop:'10px'}} variant="body2">
          Problem ID : {props.data.id}
        </Typography>
      </center>
      
        <Divider style={{marginTop:'10px'}}/>

        <TextField
          variant='outlined'
          fullWidth
          defaultValue={props.data.title}
          inputRef={titleRef}
          label='Problem Title'
          />
          <Divider style={{marginTop:'10px',marginBottom:'10px'}}/>
            <TextField
              variant='outlined'
              style={{width:'17%'}}
              label='Logo'
              inputRef={logoRef}
              defaultValue={props.data.logo}
              />
              <TextField
                variant='outlined'
                label='Grade'
                type='number'
                style={{marginLeft:'1%',width:'15%'}}

                inputRef={gradeRef}
                defaultValue={props.data.grade}
                />
            <Select value={difficulty} onChange={handleDifficulty} variant='outlined' native style = {{width: '15%',marginLeft:'1%'}}>
                <option value={0}>Difficulty</option>
                <option value={1}>Easy</option>
                <option value={2}>Medium</option>
                <option value={3}>Hard</option>
              </Select>
              <Select value={isForTest} onChange={handleTest} variant='outlined' native style = {{width: '15%',marginLeft:'1%'}}>
                <option value={0}>Problem Type</option>
                <option value={1}>For Test</option>
                <option value={2}>For Practce</option>
               
              </Select>
            <Select value={language} onChange={handleLanguage} variant='outlined' native style = {{width: '15%',marginLeft:'1%'}}>
                <option value={0}>Language</option>
                {
                  languages.map((lang,ind)=>{
                    return(
                      <option value={(ind+1)}>{lang}</option>
                    )
                  })
                }
              </Select>
            <TextField
              variant='outlined'
              label='Categorization'
              style={{marginLeft:'1%',width:'15%'}}
              
              inputRef={catRef}
              defaultValue={props.data.category}
              />
          <Divider style={{marginTop:'10px'}}/>
          <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
            Problem Description (Optional but recommended):
          </Typography>
          <MDEditor

            value={mdDescription}
            onChange={setMdDescription}
            height='300'
            commands={[
              commands.title,
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.orderedListCommand,
              commands.unorderedListCommand,
              commands.code,
              commands.image,
              commands.link,
              commands.quote,
              commands.divider,
              commands.codeEdit,
              commands.codeLive,
              commands.codePreview
            ]}

            />

        <Divider style={{marginTop:'10px'}}/>
        <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
            LaTeX Description (Optional but recommended):
        </Typography>

        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextField
                    multiline
                    rows={16}
                    value={latex}
                    onChange={handleLatexChange}
                    fullWidth
                    variant={'outlined'}
                    />
            </Grid>
            <Grid item xs={6}>
                <MathJaxContext>
                    <MathJax>{latex}</MathJax>
                </MathJaxContext>
                {/*<Latex>{latex}</Latex>*/}
            </Grid>
        </Grid>





            <Divider style={{marginTop:'10px'}}/>
            <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
              Problem Statement (Be specific):
            </Typography>
            <MDEditor
              value={mdStatement}
              onChange={setMdStatement}
              height='300'
              commands={[
                commands.title,
                commands.bold,
                commands.italic,
                commands.strikethrough,
                commands.hr,
                commands.orderedListCommand,
                commands.unorderedListCommand,
                commands.code,
                commands.image,
                commands.link,
                commands.quote,
                commands.divider,
                commands.codeEdit,
                commands.codeLive,
                commands.codePreview
              ]}

              />

      <Divider style={{marginTop:'10px'}}/>
        <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
            Hint (Optional):
        </Typography>
        <TextField
            type='number'
            variant='outlined'
            value={hintN}
            label='n(hints)'
            style={{width:'10%',marginLeft:'1%'}}
            onChange={handleHintN}/>
      <Hint ref={hintRef} n={hintN} data={props.data}/>

              <Divider style={{marginTop:'10px'}}/>

              <TextField
                variant='outlined'
                label='Restrictions (optional,delimit using |)'
                fullWidth
                style={{marginTop:'10px'}}
                multiline
                rows={3}
                variant='outlined'
                inputRef={restrictionsRef}
                defaultValue={'restrictions' in props.data ?detailsString(parse(props.data.restrictions)): []}
                // defaultValue={'restrictions' in props.data?detailsString(props.data.restrictions):''}
                />

                <Divider style={{marginTop:'10px'}}/>

                <TextField
                  variant='outlined'
                  label='Tags (optional,delimit using |)'
                  fullWidth
                  style={{marginTop:'10px'}}
                  variant='outlined'
                  inputRef={tagsRef}
                  // defaultValue={'tags' in props.data ? detailsString(parse(props.data.tags)) : []}
                  defaultValue={'tags' in props.data?detailsString(props.data.tags):''}
                  />

                <Divider style={{marginTop:'10px',marginBottom:'10px'}}/>

                <Select value={interactiveType} onChange={handleInteractiveType} variant='outlined' native style = {{width: '24%',marginLeft:'1%'}}>
                    <option value={0}>Specify Interactive Type</option>
                    <option value={1}>None</option>
                    <option value={2}>Exclusion Grid</option>
                    <option value={3}>Drag and drop</option>
                  <option value={4}>Grouping Containers</option>
                  <option value={5}>Rearranging</option>
                  <option value={6}>Matchsticks</option>
                  <option value={7}>Venn</option>
                    <option value={8}>Drag and drop grid</option>
                </Select>
                {
                  interactiveType>0?(
                    interactiveType==1?(
                      <Select value={ansType} onChange={handleAnsType} variant='outlined' native style = {{width: '24%',marginLeft:'1%'}}>
                          <option value={0}>Specify Answer Type</option>
                          <option value={1}>MCQ</option>
                          <option value={2}>Text</option>
                        </Select>
                    ):(
                      <Select value={ansType} onChange={handleAnsType} variant='outlined' native style = {{width: '24%',marginLeft:'1%'}}>
                          <option value={0}>Specify Answer Type</option>
                          <option value={1}>MCQ</option>
                          <option value={2}>Text</option>
                          <option value={3}>Interactive</option>
                        </Select>
                    )
                  ):(
                    <div/>
                  )
                }
                {
                  interactiveType>1?(
                    <div>
                      <Divider style={{marginTop:'10px',marginBottom:'10px'}}/>
                      <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
                        Questionnaire Arena:
                      </Typography>
                      <Questionnaire questionnaire={'questionnaire' in props.data?JSON.parse(props.data.questionnaire):null} ref={questionnaireRef} interactiveType={interactiveType}/>
                    </div>
                  ):(
                    <div/>
                  )
                }
                {
                  ansType>0?(
                    <div>
                      <Divider style={{marginTop:'10px',marginBottom:'10px'}}/>
                      <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
                        Solution Arena:
                      </Typography>
                      <AnsType loaded={loaded} ref={solRef} questionnaire={questionnaireRef.current!=undefined?JSON.parse(JSON.stringify(questionnaireRef.current.getData())):null} ansType={ansType} interactiveType={interactiveType} data={props.data}/>
                      <Divider style={{marginTop:'10px'}}/>
                      <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
                        Explanation :
                      </Typography>
                      <MDEditor
                        value={mdExplanation}
                        onChange={setMdExplanation}
                        height='300'
                        commands={[
                          commands.title,
                          commands.bold,
                          commands.italic,
                          commands.strikethrough,
                          commands.hr,
                          commands.orderedListCommand,
                          commands.unorderedListCommand,
                          commands.code,
                          commands.image,
                          commands.link,
                          commands.quote,
                          commands.divider,
                          commands.codeEdit,
                          commands.codeLive,
                          commands.codePreview
                        ]}

                        />
                    </div>
                  ):(
                    <div/>
                  )
                }


    </div>
  )
}

const Hint=forwardRef((props,ref)=>{

  const parse = (restrictionString) => {
    try {
      if (!restrictionString) return [];
      return JSON.parse(restrictionString);
    } catch (e) {
      console.error("Error parsing restrictions:", e);
      return [];
    }
  }
  
    var hints=[]
    if('hint' in props.data && Array.isArray(parse(props.data.hint)))
        parse(props.data.hint).map(hint=>{
            hints.push(hint)
        })

    const [data,setData]=useState(hints)

    var hintRefs=[]

    Array(props.n).fill().map((_, i) => {
        hintRefs.push(createRef())
    })

    const setHint=(hint,index)=>{
        var tmp=data
        tmp[index]=hint
        setData(tmp)
    }

    useImperativeHandle(ref, () => ({
        getData(){
            var out=[]
            data.map(hint=>{
                if(hint.length>0)
                    out.push(hint)
            })
            return out
        }
    }));

    return(
        <div>
            {
                Array(props.n).fill().map((_,i)=>{
                    return (
                        <div>
                            <Typography style={{marginTop:'10px',marginBottom:'10px'}} variant="body2">
                                Hint - {(i+1)} :
                            </Typography>
                            <MDEditor
                                value={i<data.length?data[i]:''}
                                onChange={hint=>{setHint(hint,i)}}
                                height='300'
                                style={{marginTop:'10px'}}
                                commands={[
                                    commands.title,
                                    commands.bold,
                                    commands.italic,
                                    commands.strikethrough,
                                    commands.hr,
                                    commands.orderedListCommand,
                                    commands.unorderedListCommand,
                                    commands.code,
                                    commands.image,
                                    commands.link,
                                    commands.quote,
                                    commands.divider,
                                    commands.codeEdit,
                                    commands.codeLive,
                                    commands.codePreview
                                ]}
                            />
                        </div>
                    )
                })
            }
        </div>

    )
})

export default Problem
