import React, { useEffect, useState } from 'react';
import './firebase_config';
import axios from "axios"
import { base_url } from './';
import * as firebase from 'firebase';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import Home from './components/Home';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';
import {Dialog, DialogContent, Button, TextField, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import {  Email, Lock, PersonAdd, ArrowBack } from '@material-ui/icons';
// import Alert from '@material-ui/lab/Alert';
// import GoogleIcon from '@mui/icons-material/Google';
// import { Alert } from '@material-ui/lab';
import Cookies from 'universal-cookie';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
    minWidth: '280px',
    maxWidth: '400px',
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      minWidth: '260px'
    }
  },
  dialogWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1)
    }
  },
  title: {
    textAlign: 'center',
    color: '#1a73e8',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      marginBottom: theme.spacing(1)
    }
  },
  inputField: {
    marginTop: '8px !important',
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        height: '40px'
      }
    }
  },
  button: {
    padding: theme.spacing(1.25),
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    marginTop: '8px !important',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      fontSize: '0.9rem'
    }
  },
  googleButton: {
    backgroundColor: '#fff',
    border: '1px solid #dadce0',
    color: '#3c4043',
    '&:hover': {
      backgroundColor: '#f8f9fa'
    }
  },
  divider: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: '1px solid #dadce0'
    }
  },
  dividerText: {
    padding: theme.spacing(0, 2),
    color: '#5f6368',
    fontSize: '0.875rem'
  }
}));

const cookies = new Cookies();

const COOKIE_AGE=315360000

const App = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');

  const [dialogState, setDialogState] = useState(1);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [name,setName]=useState('')
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const notify = message => {
    setMessage(message);
    setNotification(true);
  };
  const googleAuthComplete=res=>{
    //console.log(res)
    setLoading(true)
    if(res.accessToken!=undefined) {
        // googleAuth({access_token: res.accessToken}, dispatch,props.history)
 
        axios.post(base_url+'/api/v2/setter/auth/google',{
          access_token:res.accessToken
         }).then(res=>{
      // console.log(res.data)
      cookies.set('token', res.data.token, { 
        path: '/', 
        maxAge: COOKIE_AGE,
        secure: true, // Only send cookie over HTTPS
        sameSite: 'strict', // Protect against CSRF
        // httpOnly: true // Prevent XSS attacks
      })
      cookies.set('userData', JSON.stringify(res.data.user), {
        path: '/',
        maxAge: COOKIE_AGE,
        secure: true,
        sameSite: 'strict'
      })
      
      checkAuth()
         })
      
 
      }
}

  const handleCloseNotification = () => {
    setNotification(false);
  };

  const checkAuth = () => {

    if(cookies.get('token')!=null && cookies.get('token')!=undefined ){

      setAuth(true);
    }

else{        setAuth(false);
    setDialogState(1)

}
setLoading(false)
//     firebase.auth().onAuthStateChanged(function(user) {
//       if (user) {
//         setAuth(true);
        
//       } else {

//         if(cookies.get('token')!=null && cookies.get('token')!=undefined ){

//           setAuth(true);
//         }

// else{        setAuth(false);
//         setDialogState(1)

// }
//       }
//       setLoading(false);
//       setDialogState(1)
    
//     });
    
  };

  //  const googleAuth=()=>{
  //     setLoading(true)
  //     var provider = new firebase.auth.GoogleAuthProvider();
  //     firebase.auth().signInWithPopup(provider).then(function(result) {
  //       var token = result.credential.accessToken;
       
  //       var user = result.user;
  //       setToken(token)
  //       setLoading(false)
  //     }).catch(function(error) {
  //       setLoading(false)
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       var email = error.email;
  //       var credential = error.credential;
  //       console.log(error)
  //     });
  //   }
  

  const handleLogin =  () => {
    setLoading(true);
    setIsSubmitting(true);
    axios.post(base_url+'/api/v2/setter/auth/login',{
      email:email,
     
      password
 
      }).then(res=>{
   console.log(res.data)
   setIsSubmitting(false);
   setLoading(false)
   cookies.set('token', res.data.token, { 
     path: '/', 
     maxAge: COOKIE_AGE,
     secure: true, // Only send cookie over HTTPS
     sameSite: 'strict', // Protect against CSRF
     // httpOnly: true // Prevent XSS attacks
   })
   cookies.set('userData', JSON.stringify(res.data.user), {
     path: '/',
     maxAge: COOKIE_AGE,
     secure: true,
     sameSite: 'strict'
   })
   checkAuth();
  }).catch(e=>{
    setIsSubmitting(false);
    setLoading(false)

  })

    // try {
    //   await firebase.auth().signInWithEmailAndPassword(email, password);
    // } catch (error) {
    //   setError(error.message);
    //   notify(error.message);
    // } finally {
    //   setIsSubmitting(false);
    // }
  };

  const handleRegister = () => {
    setLoading(true)
    if (password !== confirmPassword) {
      // setError("Passwords don't match");
      notify("Passwords don't match");
      return;
    }
    setIsSubmitting(true);
    axios.post(base_url+'/api/v2/setter/auth/register',{
     email:email,
     name,
     password

     }).then(res=>{
  console.log(res.data)
  setIsSubmitting(false);
  cookies.set('token', res.data.token, { 
    path: '/', 
    maxAge: COOKIE_AGE,
    secure: true, // Only send cookie over HTTPS
    sameSite: 'strict', // Protect against CSRF
    // httpOnly: true // Prevent XSS attacks
  })
  cookies.set('userData', JSON.stringify(res.data.user), {
    path: '/',
    maxAge: COOKIE_AGE,
    secure: true,
    sameSite: 'strict'
  })
  setLoading(false)    
  checkAuth();
  // setAuth(true);
    
  }).catch(e=>{
    setIsSubmitting(false);
    setLoading(false) 
    console.log(e)
  })
  }
  useEffect(() => {
    checkAuth();
  }, []);



  if (!loading) {
    if (auth) {
      return <Home token={token} checkAuth={checkAuth} />;
    } else {
      return (
        <div>
          <Dialog 
            open={true} 
            maxWidth={false}
            fullScreen
            PaperProps={{ 
              style: { 
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'hidden'
              } 
            }}
          >
            <DialogContent className={classes.dialogWrapper}>
              <div className={classes.root}>
                {dialogState === 1 ? (
                  <form className={classes.form} >
                    <Typography variant="h5" className={classes.title}>
                      Welcome Back
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Email"
                      variant="outlined"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: <Email style={{ color: '#5f6368', marginRight: 8 }} />,
              
                      }}
                    />
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Password"
                      type="password"
                      variant="outlined"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        startAdornment: <Lock style={{ color: '#5f6368', marginRight: 8 }} />,
                   
                      }}
                    />
                    <Button
                    
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={handleLogin}
                    >
                      Login
                      {/* {isSubmitting ? <CircularProgress size={24} /> : 'Login'} */}
                    </Button>

                    <div className={classes.divider}>
                      <span className={classes.dividerText}>OR</span>
                    </div>

                    {/* <Button
                      className={`${classes.button} ${classes.googleButton}`}
                      onClick={googleAuth}
                      // disabled={isSubmitting}
                      // startIcon={<GoogleIcon />}
                    >
                      Continue with Google */}
                      <GoogleLogin
          clientId="758809470086-3k19svqvd9nnkm89h8ck3ig6clj7qoq4.apps.googleusercontent.com"
          render={renderProps => (
            <button 
              onClick={renderProps.onClick} 
              disabled={renderProps.disabled} 
              className={`${classes.button} ${classes.googleButton}`}
              style={{cursor:"pointer"}}

            >
              <span>Sign in with Google</span>
            </button>
          )}
          onSuccess={googleAuthComplete}
          onFailure={googleAuthComplete}
          cookiePolicy={'single_host_origin'}
        />


                    

                    <Button
                      color="primary"
                      onClick={() => setDialogState(2)}
                      startIcon={<PersonAdd />}
                    >
                      Create new account
                    </Button>
                  </form>
                ) : (
                  <form className={classes.form} >
                    <Typography variant="h5" className={classes.title}>
                      Create Account
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Full Name"
                      variant="outlined"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{
                        startAdornment: <PersonAdd style={{ color: '#5f6368', marginRight: 8 }} />,
                        style: { padding: '10px 14px' }
                      }}
                    />
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Email"
                      variant="outlined"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: <Email style={{ color: '#5f6368', marginRight: 8 }} />,
                        style: { padding: '10px 14px' }
                      }}
                    />
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Password"
                      type="password"
                      variant="outlined"

                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        startAdornment: <Lock style={{ color: '#5f6368', marginRight: 8 }} />,
                        style: { padding: '10px 14px' }
                      }}
                    />
                    <TextField
                      className={classes.inputField}
                      style={{"marginTop":"20px"}}
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        startAdornment: <Lock style={{ color: '#5f6368', marginRight: 8 }} />,
                        style: { padding: '10px 14px' }
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={handleRegister}
                    >
                      Register
                      {/* {isSubmitting ? <CircularProgress size={24} /> : 'Register'} */}
                    </Button>
                    <Button
                      color="primary"
                      style={{"marginTop":"20px"}}
                      onClick={() => setDialogState(1)}
                      startIcon={<ArrowBack />}
                    >
                      Back to Login
                    </Button>
                  </form>
                )}
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar open={notification} autoHideDuration={6000} onClose={handleCloseNotification}>
            {/* <Alert onClose={handleCloseNotification} severity={error ? "error" : "success"}> */}
              {message}
            {/* </Alert> */}
          </Snackbar>
        </div>
      );
    }
  } else {
    return (
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <Loader type="Bars" color="#0090ff" height={120} width={120} />
      </div>
    );
  }
};

export default App;